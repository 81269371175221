import React from 'react'
import { Link } from 'gatsby'

const SportsPassListItem = ({ postIndex, postItem }) => {
	console.log(postItem)
	const { num, equipement, image_, activite, jour, creneau, heures, type_sport, sport_pass, age } = postItem
	let activite_ref = activite?.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, '').replace(/ /g, '-').replace(/[^\w-]+/g, '');
	activite_ref = activite_ref.trim() + '-' + num;
	return (
		<Link className="" to={`/services-infos-pratiques/sports/annuaire-sport-pass/${activite_ref}`} key={postIndex}>
			<div className="sports-pass-list-item-content">
				<div className="sports-pass-list-item-content-picture" >
					{image_ && <img alt={equipement || ''} src={image_} />}
					<div className="sports-pass-list-item-content-picture-infos" >
						{jour && <p className="sports-pass-list-item-content-day"><span>{jour}</span></p>}
						{creneau && (creneau.toLowerCase() === "complet") && <p className="sports-pass-list-item-content-creneau">{creneau}</p>}
					</div>
				</div>
				<div className="sports-pass-list-item-content-info">
					{heures && <p className="sports-pass-list-item-content-heures">{heures}</p>}
					{equipement && <h2 className="sports-pass-list-item-content-title" >{activite}<br />{equipement}</h2>}
					{type_sport && <p className="sports-pass-list-item-content-sport-pass">{type_sport}</p>}
					{sport_pass && age && <p className="sports-pass-list-item-content-sport-pass">{sport_pass} | {age}</p>}
				</div>
			</div>
		</Link>
	)
}

export default SportsPassListItem
