exports.types = [
	{ value: '', label: 'Type de sport', className: 'option-type' },
	{ value: 'Individuels', label: 'Individuels', className: 'option-type' },
	{ value: 'Santé/Bien être', label: 'Santé/Bien être', className: 'option-type' },
	{ value: 'Collectifs', label: 'Collectifs', className: 'option-type' },
	{ value: 'En Famille', label: 'En Famille', className: 'option-type' },
];
exports.activities = [
	{ value: '', label: 'Toutes les activités', className: 'option-type' },
	// AAA
	{ value: 'ABDOS FESSIERS', label: 'ABDOS FESSIERS', className: 'option-activity' },
	{ value: 'ACCUEIL HANDICAP SPORT PASS', label: 'ACCUEIL HANDICAP SPORT PASS', className: 'option-activity' },
	{ value: 'ATHLETISME HANDI-VALID', label: 'ATHLETISME HANDI-VALID', className: 'option-activity' },	
	// BBB
	{ value: 'BABY BALLES', label: 'BABY BALLES', className: 'option-activity' },
	{ value: 'BABY BOXE', label: 'BABY BOXE', className: 'option-activity' },
	{ value: 'BADMINTON', label: 'BADMINTON', className: 'option-activity' },
	{ value: 'BASKET', label: 'BASKET', className: 'option-activity' },
	{ value: 'BIKING', label: 'BIKING', className: 'option-activity' },
	{ value: 'BMX', label: 'BMX', className: 'option-activity' },
	{ value: 'BODY SCULPT', label: 'BODY SCULPT', className: 'option-activity' },
	{ value: 'BOXE ANGLAISE', label: 'BOXE ANGLAISE', className: 'option-activity' },
	{ value: 'BOXE EDUCATIVE', label: 'BOXE EDUCATIVE', className: 'option-activity' },
	// CCC
	{ value: 'CIRCUIT FORME', label: 'CIRCUIT FORME', className: 'option-activity' },
	{ value: 'CIRCUIT TRAINING', label: 'CIRCUIT TRAINING', className: 'option-activity' },
	{ value: 'CIRCUIT TRAINING + MUSCULATION', label: 'CIRCUIT TRAINING + MUSCULATION', className: 'option-activity' },
	{ value: 'CROSS TRAINING', label: 'CROSS TRAINING', className: 'option-activity' },
	// DDD
	{ value: 'DANSE CLASSIQUE', label: 'DANSE CLASSIQUE', className: 'option-activity' },
	{ value: 'DANSE MODERN JAZZ', label: 'DANSE MODERN JAZZ', className: 'option-activity' },
	{ value: 'DIMANCHES SPORTIFS (1 fois par mois)', label: 'DIMANCHES SPORTIFS (1 fois par mois)', className: 'option-activity' },
	// EEE
	{ value: 'ESCALADE', label: 'ESCALADE', className: 'option-activity' },
	{ value: 'ESCALADE FAMILLE', label: 'ESCALADE FAMILLE', className: 'option-activity' },
	// FFF
	{ value: 'FITNESS', label: 'FITNESS', className: 'option-activity' },
	{ value: 'FIT DANSE', label: 'FIT DANSE', className: 'option-activity' },
	{ value: 'FUTSAL', label: 'FUTSAL', className: 'option-activity' },
	// GGG
	{ value: 'GYM MOTRICITÉ', label: 'GYM MOTRICITÉ', className: 'option-activity' },
	{ value: 'GYMNASTIQUE', label: 'GYMNASTIQUE', className: 'option-activity' },
	// HHH
	// III
	// JJJ
	// KKK
	// LLL
	// MMM
	{ value: 'MARCHE NORDIQUE', label: 'MARCHE NORDIQUE', className: 'option-activity' },
	{ value: 'MULTISPORTS', label: 'MULTISPORTS', className: 'option-activity' },
	{ value: 'MULTISPORTS SANTÉ', label: 'MULTISPORTS SANTÉ', className: 'option-activity' },
	{ value: 'MUSCULATION', label: 'MUSCULATION', className: 'option-activity' },
	// NNN
	// OOO
	{ value: 'OUISTITI - PARCOURS MOTRICITÉ', label: 'OUISTITI - PARCOURS MOTRICITÉ', className: 'option-activity' },
	// PPP
	{ value: 'PARCOURS MOTRICITÉ', label: 'PARCOURS MOTRICITÉ', className: 'option-activity' },
	{ value: 'PILATES', label: 'PILATES', className: 'option-activity' },
	// QQQ
	// RRR
	{ value: 'RUGBY TOUCH', label: 'RUGBY TOUCH', className: 'option-activity' },
	{ value: 'RUNNING', label: 'RUNNING', className: 'option-activity' },
	// SSS
	{ value: 'SOPHROLOGIE', label: 'SOPHROLOGIE', className: 'option-activity' },
	{ value: 'SPORT EN FAMILLE', label: 'SPORT EN FAMILLE', className: 'option-activity' },
	{ value: 'STRECHING', label: 'STRECHING', className: 'option-activity' },
	// TTT
	{ value: 'TENNIS', label: 'TENNIS', className: 'option-activity' },
	// UUU
	// VVV
	{ value: 'VÉLO APPRENTISSAGE / MULTISPORTS', label: 'VÉLO APPRENTISSAGE / MULTISPORTS', className: 'option-activity' },
	{ value: 'VÉLO APPRENTISSAGE / RANDONNÉE', label: 'VÉLO APPRENTISSAGE / RANDONNÉE', className: 'option-activity' },
	{ value: 'VÉLO SORTIE / MULTISPORTS', label: 'VÉLO SORTIE / MULTISPORTS', className: 'option-activity' },
	{ value: 'VOLLEY-BALL', label: 'VOLLEY-BALL', className: 'option-activity' },
	// WWW
	// XXX
	// YYY
	{ value: 'YOGA', label: 'YOGA', className: 'option-activity' },
	// ZZZ
];
exports.ages = [
	{ value: '', label: 'Tous les âges', className: 'option-age' },
	{ value: '16 ans et +', label: '16 ans et +', className: 'option-age' },
	{ value: '6 - 15 ans', label: '6 - 15 ans', className: 'option-age' },
	{ value: '3 - 5 ans', label: '3 - 5 ans', className: 'option-age' },
];
exports.levels = [
	{ value: '', label: 'Niveau', className: 'option-level' },
	{ value: 'Débutant', label: 'Débutant', className: 'option-level' },
	{ value: 'Intermédiaire', label: 'Intermédiaire', className: 'option-level' },
	{ value: 'Confirmé', label: 'Confirmé', className: 'option-level' },
];
exports.days = [
	{ value: '', label: 'Tous les jours', className: 'option-day' },
	{ value: 'Lundi', label: 'Lundi', className: 'option-day' },
	{ value: 'Mardi', label: 'Mardi', className: 'option-day' },
	{ value: 'Mercredi', label: 'Mercredi', className: 'option-day' },
	{ value: 'Jeudi', label: 'Jeudi', className: 'option-day' },
	{ value: 'Vendredi', label: 'Vendredi', className: 'option-day' },
	{ value: 'Samedi', label: 'Samedi', className: 'option-day' },
	{ value: 'Dimanche', label: 'Dimanche', className: 'option-day' },
];