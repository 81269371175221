import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'gatsby'
import { Container, Row, Col } from 'reactstrap'
import { Helmet } from 'react-helmet'

import logoActiveSport from '../../../img/logo.svg'

import SocialShare from "../../SocialShare"

import SportsPassBreadcrumb from '../SportsPassBreadcrumb'
import ScrollTop from '../../ScrollTop'

import { getCookiebox } from '../../../state/reducers/cookiebox'

const SportsPassSingleItemPlace = (place_restante) => {
	let places = 'Complet'
	if ( place_restante > 0 ) {
		if ( place_restante > 1 ) {
			places = place_restante + ' places restantes'
		} else {
			places = place_restante + ' place restante'
		}
	}
	return places
}

const SportsPassSingleItem = ({postItem, shareUrl, cookiebox}) => 
	<div>
		{postItem && 
			<div>
				<Helmet>
					<title>{postItem.activite || ''}</title>
				</Helmet>
				<SportsPassBreadcrumb SingleTitle={postItem.activite || ''} />
				<Container className="sports-pass-single-item">
					<Row>
						<Col tag="aside" lg="4" md="12" >
							<div className="anchors-sidebar h-100 pr-5">
								<div className="bg-light h-100 sports-pass-single-item-left">
									{postItem.lieu &&
										<>
											<p className="sports-pass-single-item-field sports-pass-single-item-location" >
												<span>{postItem.salle}</span><br />
												<a rel="noopener noreferrer" href={`http://maps.google.fr/maps?f=q&hl=fr&q=${postItem.lieu}`} target="_blank" >
													{postItem.lieu && <>{postItem.lieu}<br /></>}
												</a>
											</p>
											<hr />
										</>
									}
									<p className="sports-pass-single-item-field sports-pass-single-item-contact" >
										<span className="label">Pour plus d'infos : </span><br />
										<span>Service des Sports</span><br />
										<a href="tel:0320664763">03.20.66.47.63</a><br />
										<a href="mailto:service.sport@ville-roubaix.fr">service.sport@ville-roubaix.fr</a><br />
										<a rel="noopener noreferrer" href="https://www.ville-roubaix.fr/services-infos-pratiques/sports/pratiquer-un-sport-de-detente/" target="_blank">Toutes les infos du Sport Pass</a><br />
										<a rel="noopener noreferrer" href="https://www.facebook.com/groups/sportpassroubaix" target="_blank">Facebook "Sport Pass Roubaix"</a>
										<img className="sports-pass-single-item-field-label" alt="" src={logoActiveSport} width="100%" height="" />
									</p>
									<SocialShare shareUrl={shareUrl}/>
								</div>
							</div>
						</Col>
						<Col tag="article" md="12" lg="8" className="py-md-5 page-content position-relative">
							<div className="sports-pass-single-item-right" >
								<Link to="/services-infos-pratiques/sports/annuaire-sport-pass/" className="sports-pass-single-link-back d-lg-none d-md-none d-sm-block d-xs-block " >Retourner vers l'annuaire sport pass</Link>
								{postItem.activite && <h1 className="sports-pass-single-item-title" >{postItem.activite}</h1>}
								<div className="sports-pass-single-item-content-infos">
									{postItem.jour && <p className="sports-pass-single-item-content-category">{postItem.jour}</p>}
									{postItem.heures && <p className="sports-pass-single-item-content-subcategory hours">{postItem.heures}</p>}
									<div className="sports-pass-single-item-content-subcategory sports-pass-single-item-content-places">{SportsPassSingleItemPlace(postItem.place_restante)}</div>
								</div>
								{postItem.sports && <p className="sports-pass-single-item-content-subinfo">{postItem.sports}</p>}
								{postItem.sport_pass && <p className="sports-pass-single-item-content-subinfo"><span className="sepa">|</span>{postItem.sport_pass}</p>}
								{postItem.age && <p className="sports-pass-single-item-content-subinfo"><span className="sepa">|</span>{postItem.age}</p>}
								{postItem.niveau && <p className="sports-pass-single-item-content-subinfo"><span className="sepa">|</span>{postItem.niveau}</p>}
								{postItem.image &&
									<p className="sports-pass-single-item-picture" >
										<span className="sports-pass-single-item-picture-flou" style={{backgroundImage: 'url(' +postItem.image + ')'}} ></span>
										<img alt={postItem.salle || ''} src={postItem.image} />
									</p>
								}
								{postItem.infos &&
									<p className="sports-pass-single-item-infos" >
										<span className="infos-label">INFOS : </span><br />{postItem.infos}
									</p>
								}
								{postItem.description && <p className="sports-pass-single-item-text" > {postItem.description} </p>}
								{postItem.coach_1 &&
									<>
										<p className="sports-pass-single-item-label coach-label" >
											Coach : <span className="sports-pass-single-item-text coach-text" >{postItem.coach_1}{(postItem.coach_2) ? ' - ' + postItem.coach_2 : ''}{(postItem.coach_3) ? ' - ' + postItem.coach_3 : ''}{(postItem.coach_4) ? ' - ' + postItem.coach_4 : ''}</span>
										</p>
									</>
								}
                                { (postItem.sport_pass && ( postItem.sport_pass.toLowerCase() === 'adulte' || postItem.sport_pass.toLowerCase() === 'famille' ) ) && 
                                    <div className="sport-pass-single-item-tarifs">
                                        <p className="sports-pass-single-item-text tarif-text tarif-text-title"><strong>Tarifs 2023 / limité à deux activités par semaine :</strong></p>
                                        <ul>
                                            <li>Roubaisien* : 45 euros</li>
                                            <li>Non Roubaisien : 100 euros</li>
                                        </ul>
                                        <p className="sports-pass-single-item-text tarif-text">
                                            <strong>Tarifs « fin de saison » pour une inscription à partir des vacances d'hiver 2024 (février) / limité à deux activités par semaine :</strong>
                                        </p>
                                        <ul>
                                            <li>Roubaisien* : 23 euros</li>
                                            <li>Non Roubaisien : 70 euros</li>
                                        </ul>
										<p className="sports-pass-single-item-text tarif-text tarif-text-subtitle"><i>*Les étudiants inscrits dans des établissements d'enseignement supérieur de Roubaix se verront appliquer le tarif "Roubaisiens" quel que soit leur lieu de domiciliation</i></p>
                                    </div>
                                }
                                { (postItem.sport_pass && (postItem.sport_pass.toLowerCase() === 'enfant' || postItem.sport_pass.toLowerCase() === 'famille') ) && 
                                    <div className="sport-pass-single-item-tarifs">
                                        <p className="sports-pass-single-item-text tarif-text tarif-text-title"><strong>Tarifs enfants 2023 / limité à deux activités par semaine :</strong></p>
                                        <ul>
                                            <li>Roubaisien (selon le QF) : de 17 euros à 65 euros (dégressif à partir du 2ème enfant)</li>
                                            <li>Non Roubaisien : 100 euros</li>
                                        </ul>
                                        <p className="sports-pass-single-item-text tarif-text">
                                            <strong>Tarifs « fin de saison » enfants pour une inscription à partir des vacances d'hiver 2024 (février) / limité à deux activités par semaine :</strong>
                                        </p>
                                        <ul>
                                            <li>Roubaisien (selon le QF) : de 10 euros à 30 euros</li>
                                            <li>Non Roubaisien : 70 euros</li>
                                        </ul>
                                    </div>
                                }
                                <p className="sports-pass-single-item-label" >Inscription : </p>
                                <p className="sports-pass-single-item-text" >L'inscription se fait directement auprès des éducateurs sportifs, aux lieux et horaires des activités choisies. Une fois la fiche validée par l'éducateur, le paiement est à effectuer dans une des <Link to='/services-infos-pratiques/services-mairies-de-quartiers/les-mairies-de-quartiers/coordonnees-des-mairies-de-quartiers/'>Mairies de Quartiers.</Link><br />
                                <strong>Un justificatif de domicile de moins de 3 mois</strong> et pour les enfants une <strong>attestation de la CAF</strong> sont nécessaires. L’adhésion à la première activité donne accès aux autres créneaux du sport Pass, sous réserve des capacités d’accueils.</p>

                                <p className="sports-pass-single-item-label" >Pendant les vacances scolaires :</p>
                                <ul>
                                    <li>Aucune séance « Sport Pass Enfant ».</li>
                                    <li>Quelques créneaux « Sport Pass Adulte » sont maintenus en fonction de la présence des éducateurs / éducatrices. (Contacter le service des sports au 03.20.66.47.63 ou par <a href='mailto:service.sport@ville-roubaix.fr'>mail</a>)</li>
                                </ul>
								{postItem.quelles_aides &&
									<>
										<p className="sports-pass-single-item-label" >
											Comment en bénéficier ?
										</p>
										<p className="sports-pass-single-item-text" >
											{postItem.comment_en_beneficier}
										</p>
									</>
								}
								{postItem.structure_contact &&
									<>
										<p className="sports-pass-single-item-label" >
											Qui contacter ?
										</p>
										<p className="sports-pass-single-item-text" >
											{postItem.structure_contact && <>{postItem.structure_contact}<br /></>}
											{postItem.adresse_principale && <>{postItem.adresse_principale}<br /></>}
											{postItem.contact && <>{postItem.contact}<br /></>}
											{postItem.courriel_1 && <><a rel="noopener noreferrer" href={`mailto:${postItem.courriel_1}`} target="_blank">{postItem.courriel_1}</a><br /></>}
											{postItem.site_internet && <><a rel="noopener noreferrer" href={postItem.site_internet} target="_blank">{postItem.site_internet}</a><br /></>}
										</p>
										<p className="sports-pass-single-item-text" >
											{postItem.adresse_2 && <>{postItem.adresse_2}<br /></>}
											{postItem.contact_2 && <>{postItem.contact_2}<br /></>}
											{postItem.courriel_2 && <><a rel="noopener noreferrer" href={`mailto:${postItem.courriel_2}`} target="_blank">{postItem.courriel_2}</a><br /></>}
										</p>
										<p className="sports-pass-single-item-text" >
											{postItem.adresse_3 && <>{postItem.adresse_3}<br /></>}
											{postItem.contact_3 && <>{postItem.contact_3}<br /></>}
											{postItem.courriel_2 && <><a rel="noopener noreferrer" href={`mailto:${postItem.courriel_2}`} target="_blank">{postItem.courriel_2}</a><br /></>}
										</p>
										<p className="sports-pass-single-item-text" >
											{postItem.adresse_4 && <>{postItem.adresse_4}<br /></>}
											{postItem.contact_4 && <>{postItem.contact_4}<br /></>}
											{postItem.courriel_4 && <><a rel="noopener noreferrer" href={`mailto:${postItem.courriel_4}`} target="_blank">{postItem.courriel_4}</a><br /></>}
										</p>
										<p className="sports-pass-single-item-text" >
											{postItem.adresse_5 && <>{postItem.adresse_5}<br /></>}
											{postItem.contact_5 && <>{postItem.contact_5}<br /></>}
											{postItem.courriel_5 && <><a rel="noopener noreferrer" href={`mailto:${postItem.courriel_5}`} target="_blank">{postItem.courriel_5}</a><br /></>}
										</p>
									</>
								}
							</div>
							<ScrollTop/>
						</Col>
					</Row>
				</Container>
			</div>
		}
	</div>

const mapStateToProps = state => ({
	cookiebox: getCookiebox(state)
})

export default connect(mapStateToProps)(SportsPassSingleItem)
